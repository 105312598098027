<template>
  <div>
    <div class="dashboard-wraper mt-3" v-if="!loading">
      <div class="row mb-3">
        <div class="col-md-12">
          <a href="#" @click.prevent="" class="btn btn-info float-end py-1"
            >Status: {{ request.status }}</a
          >
          <h3>Request #{{ request.id }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h4 class="text-muted">User Information</h4>
              <div class="row">
                <div class="col-md-6">
                  <label>Name</label>
                  <p>{{ request.name }}</p>
                </div>
                <div class="col-md-6">
                  <label>Email</label>
                  <p>{{ request.email }}</p>
                </div>
                <div class="col-md-6">
                  <label>Phone Number</label>
                  <p>{{ request.phone_number }}</p>
                </div>
                <div class="col-md-6">
                  <label>Requested</label>
                  <p>{{ request.created_at }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="card">
            <div class="card-body">
              <h4 class="text-muted">Property Details</h4>
              <div class="row">
                <div class="col-md-6">
                  <label>Desired Date</label>
                  <p>{{ request.date }}</p>
                </div>
                <div class="col-md-6">
                  <label>Category</label>
                  <p>{{ request.category }}</p>
                </div>
                <div class="col-md-6">
                  <label>Region</label>
                  <p>{{ request.region }}</p>
                </div>
                <div class="col-md-12">
                  <label>House Details</label>
                  <p>{{ request.category_details }}</p>
                </div>
                <div class="col-md-12">
                  <label>Region Details</label>
                  <p>{{ request.region_details }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3" v-if="request.status == 'active'">
          <a
            href="#"
            @click.prevent="setAction('complete')"
            class="btn btn-success"
            >Mark Complete</a
          >
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      request: { package: {} },
      show_package_details: false,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/my-account/field-assistants/${this.$route.params.id}`)
        .then((response) => {
          this.request = response.data.request;
          this.loading = false;
        });
    },

    setAction(status) {
      this.loading = true;

      this.$axios
        .put(`/api/v1/my-account/field-assistants/${this.$route.params.id}`, { status: status })
        .then(() => {
          this.loading = false;
          this.fetch()
        });
    }
  },
};
</script>
